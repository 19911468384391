import { HttpClient, HttpHeaders } from "@angular/common/http";
import { lastValueFrom } from "rxjs";
import { ODataResponse } from "./odata-response";
import { environment } from "src/environments/environment";
import { OrderDetailsApi } from "./orderDetails-api";
import { API_KEY } from "src/app/app.apiKey";


    export class ODataStore<Api> {
        private static baseApiUrl = environment.apiUrl;
        private readonly httpClient;
        private readonly odataEntity;

        constructor(httpClient: HttpClient, odataEntity: string) {
            this.httpClient = httpClient;
            this.odataEntity = odataEntity;
        }

        async queryIn(member: string, ...values:unknown[]){
            var headers = new HttpHeaders({'Ocp-API-FlashMag-Key': API_KEY})
            const searchParams = new URLSearchParams();
            const searchClauses: string[] = [];
            if (values?.length)
                searchClauses.push(...values.map(v => `${member} eq ${v}`));
            let filter = searchClauses.join(' or ');        
            searchParams.append('$filter', filter);
            return (await lastValueFrom(
                this.httpClient.get<ODataResponse<Api[]>>(`${ODataStore.baseApiUrl}/odata/${this.odataEntity}?${searchParams.toString()}`, { headers: headers })
            )).value;
        }

        async query(options: {
            orderby?: string;
            search?: string | number;
            containInMembers?: string[];
            equalInMembers?: string[];
            agencyId?: number;
            parentAgencyId?: number;
            componentType?: string;
        }
        ): Promise<Api[]> {
            var headers = new HttpHeaders({'Ocp-API-FlashMag-Key': API_KEY})

            const searchParams = new URLSearchParams();
            if (!!options.orderby) searchParams.append('$orderby', options.orderby);

            const searchClauses: string[] = [];
            if (!!options.search && !!(options.containInMembers?.length)) 
                searchClauses.push(...options.containInMembers.map(m => `contains(${m},'${options.search}')`));

            if (!!options.search && !!(options.equalInMembers?.length))
                searchClauses.push(...options.equalInMembers.map(m => `${m} eq '${options.search}'`)); 

            let filter = searchClauses.join(' or ');
            if (options.agencyId !== undefined) {
                const agencyClause = `agencyId eq ${options.agencyId}`;
                filter = filter.length > 0 ?
                    [`(${filter})`, agencyClause].join(' and ') :
                    agencyClause;
            }
            if (options.parentAgencyId !== undefined) {
                const agencyClause = `agencyId eq ${options.parentAgencyId}`;
                filter = filter.length > 0 ?
                    [`(${filter})`, agencyClause].join(' or ') :
                    agencyClause;
            }
            if (options.componentType == "order"){
                const orderClause = `isValidated eq false`;
                filter = filter.length > 0 ? 
                [`(${filter})`, orderClause].join(' and ') : 
                orderClause;
            }
            if (filter.length)
                searchParams.append('$filter', filter);
            return (await lastValueFrom(
                this.httpClient.get<ODataResponse<Api[]>>(`${ODataStore.baseApiUrl}/odata/${this.odataEntity}?${searchParams.toString()}`, { headers: headers })
            )).value;
        }

    async getById(key: number): Promise<Api[]>{
        var headers = new HttpHeaders({'Ocp-API-FlashMag-Key': API_KEY})
        const response = (await lastValueFrom(
            this.httpClient.get<Api[]>(`${ODataStore.baseApiUrl}/odata/GetOrderItem(orderId=${key})`, { headers: headers })
        ));
        return response;
    }
}
