import { Inject, Injectable, InjectionToken } from '@angular/core';
import { AccountInfo, InteractionRequiredAuthError, PublicClientApplication } from "@azure/msal-browser";
import { BehaviorSubject } from 'rxjs';



export interface AzureConfiguration {
  clientId: string;
  authority: string;
}

export interface Identity {
  accountInfo: AccountInfo;
  accessToken: string;
}

export const AZURE_CONFIGURATION = new InjectionToken<AzureConfiguration>('AZURE_CONFIGURATION');

@Injectable({ providedIn: 'root' })
export class TokenService {
  private readonly haveIdentity$$ = new BehaviorSubject<Identity | undefined>(undefined);
  readonly haveIdentity$ = this.haveIdentity$$.asObservable();

  private publicClientApplication: PublicClientApplication;
  private scopes: Array<string>;
  private identity?: Identity;

  constructor(@Inject(AZURE_CONFIGURATION) azureConfiguration: AzureConfiguration) {
    this.publicClientApplication = new PublicClientApplication({
      auth: {
        clientId: azureConfiguration.clientId,
        authority: azureConfiguration.authority
      }
    });
    this.scopes = [`api://${azureConfiguration.clientId}/access_as_user`, 'User.Read', 'User.ReadBasic.All']; 
  }

  private async handleRedirect() {
    const redirectResponse = await this.publicClientApplication.handleRedirectPromise();
    if (redirectResponse !== null) {
      return {
        accessToken: redirectResponse.accessToken,
        accountInfo: redirectResponse.account
      } as Identity;
    }
    return undefined;
  }

  private async acquireSilently() {
    let accountInfo = this.publicClientApplication.getActiveAccount() ?? undefined;
    if (!accountInfo) {
      const accounts = this.publicClientApplication.getAllAccounts();
      if (accounts.length > 0) {
        accountInfo = accounts[0];
        this.publicClientApplication.setActiveAccount(accountInfo);
      }
    }

    // If we have one aquire silently
    if (accountInfo) {
      const accessTokenRequest = {
        scopes: this.scopes,
        account: accountInfo,
      };
      const authenticationResult = await this.publicClientApplication.acquireTokenSilent(accessTokenRequest); 
      return{
        accessToken: authenticationResult.accessToken,
        accountInfo: authenticationResult.account
      } as Identity;
    }
    return undefined;
  }

  async initialize() {
    await this.publicClientApplication.initialize();
    this.identity = (await this.handleRedirect()) ??
      (await this.acquireSilently());
    if(this.identity !== undefined)
      this.haveIdentity$$.next(this.identity);
  }

  async login(): Promise<void> {
    await this.publicClientApplication.loginRedirect({
      scopes: this.scopes
    });
  }

  async logout(): Promise<void> {
    if (this.identity)
      await this.publicClientApplication.logoutRedirect();
  }

  getBearerToken() {
    return this.identity?.accessToken;
  }

  getIdentity() {
    return this.identity?.accountInfo.username;
  }
}