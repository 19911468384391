import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { UserApi } from "./user-api";
import { MaterialApi } from "./material-api";
import { lastValueFrom } from "rxjs";
import { ScanBulk } from "./scan-bulk-api";
import { environment } from "src/environments/environment";
import { ODataStore } from "./odata-store";
import { AgencyApi } from "./agency-api";
import { StockApi } from "./stock-api";
import { OrderApi } from "./order-api";
import { OrderDetailsApi } from "./orderDetails-api";
import { API_KEY } from "src/app/app.apiKey";

@Injectable({ providedIn: 'root' })
export class FlashmagService {
    private static baseApiUrl = environment.apiUrl;
    private httpClient = inject(HttpClient);

    public agencyStore = new ODataStore<AgencyApi>(this.httpClient, 'ODataAgency');
    public userStore = new ODataStore<UserApi>(this.httpClient, 'ODataUser');
    public materialStore = new ODataStore<MaterialApi>(this.httpClient, 'ODataMaterial');
    public stockStore = new ODataStore<StockApi>(this.httpClient, 'ODataStock');
    public orderStore = new ODataStore<OrderApi>(this.httpClient, 'ODataOrder');
    public orderDetailsStore = new ODataStore<OrderDetailsApi>(this.httpClient, 'ODataOrderItem')

    async pushScanBulk(scanBulk: ScanBulk) {
        var headers = new HttpHeaders({'Ocp-API-FlashMag-Key': API_KEY})
        return await lastValueFrom(
            this.httpClient.post(`${FlashmagService.baseApiUrl}/api/Scan/bulk`, scanBulk, { headers: headers })
        );
    }

    async getVersion() {
        var headers = new HttpHeaders({'Ocp-API-FlashMag-Key': API_KEY})
        return await lastValueFrom(
            this.httpClient.get<string>(`${FlashmagService.baseApiUrl}/version`, {headers: headers} )
        );
    }

    async validateOrder(order: OrderApi){
        var headers = new HttpHeaders({'Ocp-API-FlashMag-Key': API_KEY})
        return await lastValueFrom(
            this.httpClient.put(`${FlashmagService.baseApiUrl}/api/Order`, order, {headers: headers})
        );
    }
}

